@import '../App.scss';
.app {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
.page {
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  background: rgb(2, 0, 36);
  background: -moz-linear-gradient(
    144deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(121, 24, 242, 1) 50%,
    rgba(0, 212, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    144deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(121, 24, 242, 1) 50%,
    rgba(0, 212, 255, 1) 100%
  );
  background: linear-gradient(
    144deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(121, 24, 242, 1) 50%,
    rgba(0, 212, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#00d4ff",GradientType=1);
  .container {
    max-width: 100%;
    min-height: 100%;
    height: auto;
    min-height: auto;
    animation: fadeIn 1s forwards;
  }
  p {
    color: #fff;
    font-weight: 300;
    min-width: fit-content;
    animation: pulse 1s;
    // Add animation to every paragraph one by one
    &:nth-of-type(1) {
      animation-delay: 1.1s;
    }
    &:nth-of-type(2) {
      animation-delay: 1.2s;
    }
  }
  .button-class {
    max-width: 50%;
    color: $primary-color;
    cursor: pointer;
    letter-spacing: 4px;
    font-weight: 600;
    font-size: 1.1rem;
    text-decoration: none;
    border: 1px solid #fff;
    background: transparent;
    border-radius: 4px;
    padding: 10px;

    &:hover {
      background: $primary-color;
      color: #333;
      border: none;
      animation: pulse 1s backwards;
    }
  }
  input {
    opacity: 0.9 !important;
  }
}
