html {
  margin: 0;
  padding: 0;
  max-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
  font-size: 90%;
}
body {
  font-size: 100%;
  margin: 0;
  padding: 0;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
  background-color: #181818;
}

@media screen and (max-width: 1219px) {
  html {
    font-size: 70%;
  }
  body {
    overflow-y: visible;
    overflow-x: hidden;
  }
}
