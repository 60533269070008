.login-greeting {
  margin-top: 12%;
  margin-bottom: 12%;
  max-width: 100%;
  text-align: center;
  color: #fff;
  h1 {
    margin-left: 1.5rem;
    font-size: 1.5rem;
    margin-right: 1.5rem;
  }
}
