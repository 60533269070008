.dashboard-form {
  position: relative;
  display: flex;
  width: 100%;

  .form-container {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    flex-direction: column;
    box-sizing: border-box;
    .lastName {
      margin-top: 1rem;
      margin-bottom: 2.5rem;
    }
    .form-submit-button {
      position: relative;
      bottom: 0;
      left: 33%;
    }
  }
}
